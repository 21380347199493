<template>
	<div>
		<section class="section is-main-section">
			<card-component
				:title="
					$route.params.id
						? `${form.username} Düzenle`
						: 'Kullanıcı Oluştur'
				"
				icon="domain">
				<form @submit.prevent="save">
					<b-field
						horizontal
						label="Kullanıcı Adı"
						@keyup.native="form.email = form.username + '@trustpay.tech'">
						<b-input
							size="is-small"
							v-model="form.username"
							icon="account"
							placeholder="Kullanıcı Adı"
							name="username"
							required />
					</b-field>
					<!--
          <b-field horizontal label="E-Posta">
            <b-input size="is-small" type="email" v-model="form.email" icon="email" placeholder="E-Posta" name="email"
                     required/>
          </b-field>
          -->
					<b-field
						horizontal
						label="Parola"
						v-if="form.type !== 'SYSTEM'">
						<b-input
							size="is-small"
							type="password"
							v-model="form.password"
							icon="lock"
							placeholder="******"
							name="password"
							:required="!$route.params.id" />
						<b-button
							size="is-small"
							type="is-success"
							@click.native="form.password = generateP(10)"
							>Generate</b-button
						>
            {{form.password}}
					</b-field>
					<b-field
						horizontal
						expanded
						label="Tür"
						v-if="!$store.state.user.siteId">
						<b-select size="is-small" v-model="form.type" expanded>
							<option
								:value="key"
								v-for="(item, key) in enums.userTypes">
								{{ item }}
							</option>
						</b-select>
					</b-field>
					<b-field
						horizontal
						label="Rol"
						v-if="!$store.state.user.siteId">
						<b-select
							size="is-small"
							v-model="form.roleId"
							required
							expanded>
							<option :value="item.id" v-for="item in roles" :key="item.id">
								{{ item.name }}
							</option>
						</b-select>
					</b-field>
					<b-field
						horizontal
						label="Site"
						v-if="!$store.state.user.siteId">
						<b-select
							size="is-small"
							v-model="form.siteId"
							expanded>
							<option :value="item.uuid" v-for="item in sites">
								{{ item.name }}
							</option>
						</b-select>
					</b-field>
					<b-field
						horizontal
						label="App Key"
						v-if="form.type === 'SITE'">
						<b-input size="is-small" v-model="form.appKey" />
						<b-button
							size="is-small"
							type="is-success"
							@click="form.appKey = generateP(64)"
							>Generate</b-button
						>
					</b-field>
					<b-field
						horizontal
						label="Secret Key"
						v-if="form.type === 'SITE'">
						<b-input size="is-small" v-model="form.appSecret" />
						<b-button
							size="is-small"
							type="is-success"
							@click="form.appSecret = generateP(64)"
							>Generate</b-button
						>
					</b-field>
					<b-field
						horizontal
						label="Kullanıcı Grubu"
						v-if="!$store.state.user.siteId">
						<b-input
							size="is-small"
							v-model="form.group"
							placeholder="Kullanıcı Grubu"
							name="group"
							required />
					</b-field>
          <b-field
              horizontal
              label="Son login tarihi"
              v-if="!$store.state.user.siteId">
            {{form.lastLoginDate ? dateFormat(form.lastLoginDate, dateFormats.normalDateTimeS) : '-'}}
          </b-field>
          <b-field
              horizontal
              label="Son Şifre Güncelleme Tarihi"
              v-if="!$store.state.user.siteId">
            {{form.lastUpdateDate ? dateFormat(form.lastUpdateDate, dateFormats.normalDateTimeS) : '-'}}
          </b-field>
					<b-field horizontal label="Durum">
						<b-switch size="is-small" v-model="form.status">{{form.status ? "Aktif" : "Pasif" }}</b-switch>
					</b-field>
					<hr />
					<div class="is-flex is-justify-content-end is-flex-grow-1">
						<div class="control mr-2">
							<b-button
								native-type="submit"
								type="is-primary"
								size="is-small">
								{{ $route.params.id ? "Güncelle" : "Ekle" }}
							</b-button>
						</div>
            <div class="control mr-2" v-if="$route.params.id && hasPermission('User.Reset2FA')">
              <button
                  type="button"
                  @click="reset2fa"
                  class="button is-warning mr-3 is-small"
                  :class="{ 'is-loading': isLoading }">
                2FA Kodunu Sıfırla
              </button>
            </div>
            <div class="control mr-2" v-if="$route.params.id && hasPermission('User.Reset2FA')">
              <button
                  type="button"
                  @click="loginReset"
                  class="button is-info mr-3 is-small"
                  :class="{ 'is-loading': isLoading }">
                Son Giriş Tarihini Sıfırla
              </button>
            </div>
            <div class="control mr-2" v-if="$route.params.id && hasPermission('User.Reset2FA')">
              <button
                  type="button"
                  @click="passwordReset"
                  class="button is-secondary mr-3 is-small"
                  :class="{ 'is-loading': isLoading }">
                Son Şifre değişikliği tarihini sıfırla
              </button>
            </div>
						<div class="control">
							<b-button
								type="is-primary is-outlined"
								size="is-small"
								@click="$router.go(-1)"
								>Geri Dön</b-button
							>
						</div>
					</div>
				</form>
			</card-component>
		</section>
	</div>
</template>

<script>
	import mapValues from "lodash/mapValues"
	import CardComponent from "@/components/CardComponent"
	import {
		UserService as Service,
		SiteService,
		RoleService,
		ProfileService,
	} from "@/services"

	export default {
		name: "UserCreateOrUpdate",
		components: { CardComponent },
		data() {
			return {
				isLoading: false,
				sites: [],
				roles: [],
				form: {
					username: null,
					name: null,
					email: null,
					password: null,
					siteId: null,
					roleId: null,
					status: true,
					type: "PANEL",
					group: "ALL",
					appSecret: null,
					appKey: null,
          lastLoginDate:null,
          lastUpdateDate: null
				},
			}
		},
		async mounted() {
			const sites = await new Promise(async (resolve, reject) => {
				try {
					const data = await SiteService.all({ status: 1 })
					resolve(data)
				} catch (e) {
					reject(e)
				}
			})
			const roles = await new Promise(async (resolve, reject) => {
				try {
					const data = await RoleService.all({ status: 1 })
					resolve(data)
				} catch (e) {
					reject(e)
				}
			})
			Promise.all([sites, roles]).then((values) => {
				this.sites = values[0].data
				this.roles = values[1].data
				if (this.$store.state.user.siteId) {
					this.form.type = "PANEL"
					this.form.roleId = 5
					this.form.siteId = this.$store.state.user.siteId
				}
			})

			if (this.$route.params.id) this.getData()
		},
		methods: {
			reset2fa() {
				this.isLoading = true
				ProfileService.reset2fa({ id: this.$route.params.id })
					.then(() => {
						this.$buefy.snackbar.open({
							message: "Kodunuz Sıfırlandı",
							queue: false,
						})
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					)
					.finally(() => (this.isLoading = false))
			},
      loginReset(){
        Service.update({uuid: this.$route.params.id, lastLoginDate: null})
            .then(() => {
              this.form.lastLoginDate = null
              this.$buefy.snackbar.open({
                message: "Son Giriş Tarihi Sıfırlandı",
                queue: false,
              })
            })
            .catch((err) =>
                this.$buefy.toast.open({
                  message: err.message,
                  position: "is-top-right",
                  type: "is-danger",
                })
            )
            .finally(() => (this.isLoading = false))
      },
      passwordReset(){
        Service.update({uuid: this.$route.params.id, lastUpdateDate: null})
            .then(() => {
              this.form.lastUpdateDate = null
              this.$buefy.snackbar.open({
                message: "Şifre değiştirme tarihi sıfırlandı",
                queue: false,
              })
            })
            .catch((err) =>
                this.$buefy.toast.open({
                  message: err.message,
                  position: "is-top-right",
                  type: "is-danger",
                })
            )
            .finally(() => (this.isLoading = false))
      },
			getData() {
				Service.edit(this.$route.params.id).then((res) => {
					this.form = {...res.data, password: null}
          this.form.lastLoginDate = !res.data.lastLoginDate || res.data.lastLoginDate === '1970-01-01T00:00:00.000Z' ? null : new Date(res.data.lastLoginDate)
          this.form.lastUpdateDate = !res.data.lastUpdateDate || res.data.lastUpdateDate === '1970-01-01T00:00:00.000Z' ? null : new Date(res.data.lastUpdateDate)
				})
			},
			save() {
				Service[this.$route.params.id ? "update" : "create"](this.form)
					.then(() => {
						this.$buefy.toast.open({
							message: this.$route.params.id
								? "Güncellendi"
								: "Eklendi",
							type: "is-success",
						})
						this.reset()
						this.$router.go(-1)
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					)
			},
			reset() {
				this.form = mapValues(this.form, (item) => {
					if (item && typeof item === "object") {
						return []
					}
					return null
				})
			},
		},
	}
</script>
